<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 qr-codes-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="reception-header icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: backLinkName }">
            <button>
              <icon icon="#cx-hea1-arrow-left" />
            </button>
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("utilities", "qr-codes", "edit-qr-code") }}
        </div>
        <template v-slot:right>
          <button @click="initSubmit">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>

    <ul
      v-if="selectedQrCode && selectedQrCode.type"
      class="clebex-item-section"
    >
      <!-- Type -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-section-input">
          <label class="clebex-section-input-label">
            {{ displayLabelName("utilities", "qr-codes", "type") }}
          </label>
          <p class="text-label">
            {{ selectedQrCode.type }}
          </p>
        </div>
      </li>
      <!-- Element ID -->
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-section-input">
          <label class="clebex-section-input-label">
            {{ displayLabelName("utilities", "qr-codes", "element-id") }}
          </label>
          <p class="text-label">
            {{ selectedQrCode.element_id }}
          </p>
        </div>
      </li>
    </ul>

    <VeeForm
      ref="editWrCodeForm"
      @submit="submitForm"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      style="height: auto !important;"
      :initial-values="{
        ...selectedQrCode
      }"
      novalidate
    >
      <ul class="clebex-item-section">
        <!-- Template -->
        <li class="clebex-item-section-item full-right-underline">
          <router-link
            :to="{
              name: 'r_select-qr-code-template',
              params: $route.params
            }"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">
                {{ displayLabelName("utilities", "qr-codes", "template") }}
              </label>
              <p
                v-if="selectedTemplate && selectedTemplate.name"
                class="text-label"
              >
                {{ selectedTemplate.name }}
              </p>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
          <Field
            name="template_id"
            as="input"
            type="hidden"
            v-model="templateId"
          />
        </li>
        <!-- End Template -->
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <label class="clebex-section-input-label">
                  <div class="checkbox slide">
                    <input
                      id="neverExpires"
                      type="checkbox"
                      v-model="neverExpires"
                    />
                    <label for="neverExpires"></label>
                  </div>
                </label>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{ displayLabelName("utilities", "qr-codes", "never-expires") }}
              </dd>
            </dl>
          </div>
        </li>

        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">
              {{ displayLabelName("utilities", "qr-codes", "validity-start") }}
            </label>
            <p class="text-label">
              {{ formattedValidityStart }}
            </p>
          </div>
        </li>

        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">
              {{ displayLabelName("utilities", "qr-codes", "validity-end") }}
            </label>
            <p class="text-label">
              {{ formattedValidityEnd }}
            </p>
          </div>
        </li>

        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <label class="clebex-section-input-label">
                  <div class="checkbox slide">
                    <input
                      id="instantValidation"
                      type="checkbox"
                      v-model="instantValidation"
                    />
                    <label for="instantValidation"></label>
                  </div>
                </label>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{
                  displayLabelName(
                    "utilities",
                    "qr-codes",
                    "instant-validation"
                  )
                }}
              </dd>
            </dl>
          </div>
        </li>

        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <label class="clebex-section-input-label">
                  <div class="checkbox slide">
                    <input
                      id="validFromFirstUse"
                      type="checkbox"
                      v-model="validFromFirstUse"
                    />
                    <label for="validFromFirstUse"></label>
                  </div>
                </label>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{
                  displayLabelName(
                    "utilities",
                    "qr-codes",
                    "valid-from-first-use"
                  )
                }}
              </dd>
            </dl>
          </div>
        </li>

        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.valid_for_minutes }"
        >
          <span class="error-message" v-if="errors.valid_for_minutes">
            {{ errors.valid_for_minutes }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="valid_for_minutes">
                {{
                  displayLabelName("utilities", "qr-codes", "valid-for-minutes")
                }}
              </label>
              <Field
                name="valid_for_minutes"
                as="input"
                type="text"
                rules="required"
              />
            </div>
          </div>
        </li>

        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.valid_time_use }"
        >
          <span class="error-message" v-if="errors.valid_time_use">
            {{ errors.valid_time_use }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="valid_time_use">
                {{
                  displayLabelName("utilities", "qr-codes", "times-used-valid")
                }}
              </label>
              <Field
                name="valid_time_use"
                as="input"
                type="text"
                rules="required"
              />
            </div>
          </div>
        </li>

        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.time_used }"
        >
          <span class="error-message" v-if="errors.time_used">
            {{ errors.time_used }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="time_used">
                {{ displayLabelName("utilities", "qr-codes", "times-used") }}
              </label>
              <Field name="time_used" as="input" type="text" rules="required" />
            </div>
          </div>
        </li>
      </ul>

      <Field
        name="regenerate_after_days"
        as="input"
        type="hidden"
        rules="required"
      />

      <ul class="clebex-item-section" v-if="selectedQrCode">
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">
                {{ displayLabelName("utilities", "qr-codes", "status") }}
              </label>
              <p
                :class="
                  selectedQrCode.status.valid
                    ? 'text-label status-description-valid'
                    : 'text-label status-description-invalid'
                "
              >
                {{ statusDescription }}
              </p>
            </div>
          </div>
        </li>
      </ul>

      <ul
        class="clebex-item-section"
        v-if="selectedQrCode && selectedQrCode.image"
      >
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <img :src="selectedQrCode.image" alt="" />
            </div>
          </div>
        </li>
      </ul>

      <Field name="is_active" as="input" type="hidden" />
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState, mapGetters } from "vuex";
import { displayDateWithTimezone } from "@/services/helpers";

export default {
  name: "Edit Qr Code",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  data() {
    return {
      neverExpires: false,
      instantValidation: false,
      validFromFirstUse: false,
      validityStart: "",
      validityEnd: "",
      statusDescription: "QR Code is valid."
    };
  },
  computed: {
    ...mapState("qrCode", ["selectedQrCode", "selectedTemplate"]),
    ...mapState("timezone", ["timezones"]),
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    qrCodeId() {
      const { qrCodeId } = this.$route.params;
      return qrCodeId;
    },
    formattedValidityStart() {
      return this.validityStart && this.timezones
        ? `${this.displayDate(
            this.validityStart,
            this.globalDateFormat ? this.globalDateFormat : "DD MMMM YYYY"
          )} ${this.displayDate(
            this.validityStart,
            this.globalTimeFormat ? this.globalTimeFormat : "HH:mm"
          )}`
        : "";
    },
    formattedValidityEnd() {
      return this.validityEnd && this.timezones
        ? `${this.displayDate(
            this.validityEnd,
            this.globalDateFormat ? this.globalDateFormat : "DD MMMM YYYY"
          )} ${this.displayDate(
            this.validityEnd,
            this.globalTimeFormat ? this.globalTimeFormat : "HH:mm"
          )}`
        : "";
    }
  },
  methods: {
    ...mapActions("qrCode", [
      "setSelectedQrCode",
      "findQrCode",
      "updateQrCode",
      "findTemplate",
      "setSelectedTemplate"
    ]),
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    },
    submitForm(values) {
      if (values) {
        values.never_expires = Number(this.neverExpires);
        values.instant_validation = Number(this.instantValidation);
        values.valid_from_first_use = Number(this.validFromFirstUse);
        values.template_id = this.selectedTemplate.id;
        values.validity_start = this.validityStart;
        values.validity_end = this.validityEnd;

        this.updateQrCode({ data: values, id: this.qrCodeId }).then(() => {
          this.setSelectedTemplate(null);
          this.$router.push({ name: this.backLinkName });
        });
      }
    },
    templateId() {
      if (this.selectedTemplate && this.selectedTempalte.id) {
        return this.selectedTemplate.id;
      }
      return null;
    },
    displayDate(date, format) {
      if (this.timezones && this.timezones.data) {
        return displayDateWithTimezone({
          date,
          format,
          timezones: this.timezones
        });
      }
      return "";
    }
  },
  watch: {
    qrCodeId: {
      immediate: true,
      handler(value) {
        if (value && typeof value !== undefined) {
          this.findQrCode(value).then(qrCode => {
            this.validityStart = qrCode.validity_start;
            this.validityEnd = qrCode.validity_end;
            this.setSelectedTemplate(qrCode.template);
            this.neverExpires = Boolean(qrCode.never_expires);
            this.instantValidation = Boolean(qrCode.instant_validation);
            this.validFromFirstUse = Boolean(qrCode.valid_from_first_use);

            if (qrCode.status.valid == false)
              this.statusDescription = qrCode.status.description;
            else this.statusDescription = "QR Code is valid.";

            return qrCode;
          });
        }
      }
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
